<template>
  <v-layout class="map-cont">
    <v-layout v-if="showLegend" class="overflow-auto" style="width: 52%">
      <v-layout row class="itinerary-sidebar">
        <v-layout class="ml-2 mr-2" style="flex-wrap: wrap">
          <v-flex class="legend-item mb-2 no-grow">
            <ReservationTrackingMapProviderSelector
              :selected-providers="selectedProviders"
              :referred-providers="referredProviders"
              @update-selected-providers="
                (newSelectedProviders) =>
                  changeSelectedProviders(newSelectedProviders)
              "
            />
          </v-flex>
          <v-flex
            v-if="localCurrentTripVehicleGroups.length > 0"
            class="legend-item mb-2 no-grow"
          >
            <ReservationTrackingMapVehicleGroupSelector
              :selected-trip-vehicle-group-id="selectedTripVehicleGroupId"
              :current-trip-vehicle-groups="localCurrentTripVehicleGroups"
              @update-selected-trip-vehicle-group-ids="
                (newSelectedTripVehicleGroupId) =>
                  changeSelectedTripVehicleGroup(newSelectedTripVehicleGroupId)
              "
            />
          </v-flex>
          <v-flex class="legend-item mb-2 no-grow">
            <ReservationTrackingMapVehicleJourneySelector
              v-if="currentJourneysBySelectedTripVehicleGroup.length > 0"
              :current-journeys-by-trip-vehicle-group="
                currentJourneysBySelectedTripVehicleGroup
              "
              :selected-journeys="currentSelectedVehicleJourneys"
              :simulated-trip-assignment-vehicles="
                simulatedTripAssignmentVehicles
              "
              @update-selected-journeys="
                (newSelectedJourneys) =>
                  changeSelectedVehicleJourneys(newSelectedJourneys)
              "
            />
          </v-flex>
        </v-layout>
        <v-expansion-panel
          v-if="
            isAcceleratedPaymentsEnabled && isReservationFinished && !isLoading
          "
          v-model="trackingSummaryPanel"
          flat
          class="margin-t-6 margin-l-2"
        >
          <v-expansion-panel-content>
            <template #header>
              <v-layout column class="padding-b-2">
                <v-flex>
                  <p class="tracking-summary-title">Tracking Summary</p>
                </v-flex>
                <v-layout
                  column
                  v-for="(provider, providerIndex) in selectedProviders"
                  :key="providerIndex"
                  class="margin-t-2"
                >
                  <v-layout
                    row
                    v-if="provider.computedName !== 'All'"
                    style="font-size: 13px; align-items: center"
                    class="font-weight-bold text-gray"
                  >
                    <img
                      v-if="trackingSummaryPanel === null"
                      width="14"
                      height="14"
                      class="margin-r-2"
                      :src="
                        referralTrackingValues[provider.reservationId]
                          ? require('@/assets/images/tracking-check.png')
                          : require('@/assets/images/tracking-x.png')
                      "
                    />
                    <div>{{ provider.companyName }}</div>
                  </v-layout>
                  <v-layout row>
                    <table
                      v-if="
                        provider.computedName !== 'All' &&
                        trackingSummaryPanel !== null
                      "
                      class="font-12"
                    >
                      <tr
                        v-for="(journey,
                        journeyIndex) in getJourneysForProvider(
                          provider.companyId
                        )"
                        :key="journeyIndex"
                      >
                        <td
                          style="
                            width: 128px;
                            display: inline-flex;
                            align-items: center;
                          "
                        >
                          <img
                            height="14"
                            width="14"
                            :src="
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .onTime &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedArrival &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedOverall
                                ? require('@/assets/images/tracking-check.png')
                                : require('@/assets/images/tracking-x.png')
                            "
                          />
                          <div class="font-weight-bold text-gray margin-l-2">
                            {{ getJourneyVehicleName(journey.vehicle) }}
                          </div>
                        </td>
                        <td
                          style="display: inline-flex; align-items: center"
                          class="margin-l-2"
                        >
                          <CRIcon
                            :color="
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedArrival
                                ? 'gray'
                                : 'errorNew'
                            "
                            view-box="-4 0 30 24"
                          >
                            {{
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedArrival
                                ? 'check'
                                : 'close'
                            }}
                          </CRIcon>
                          <v-tooltip content-class="tracking-summary-tooltip" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                style="font-size: 13px"
                              >
                                Tracked Arrival {{ trackedArrivalDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled ? ' *' : '' }}
                              </span>
                            </template>
                            <span>
                              {{
                                vehicleTrackingSummary[
                                  journey.vehicle.vehicleId
                                ]?.trackedArrivalMessage
                              }}
                              <span v-if="trackedArrivalDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled" style="font-style: italic">
                                </br></br>
                                * This status has been flagged as inaccurate from disputes.
                              </span>
                            </span>
                          </v-tooltip>
                        </td>
                        <td
                          style="display: inline-flex; align-items: center"
                          class="margin-l-2"
                        >
                          <CRIcon
                            :color="
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .onTime
                                ? 'gray'
                                : 'errorNew'
                            "
                            view-box="-4 0 30 24"
                          >
                            {{
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .onTime
                                ? 'check'
                                : 'close'
                            }}
                          </CRIcon>
                          <v-tooltip content-class="tracking-summary-tooltip" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                style="font-size: 13px"
                              >
                                On-Time {{ onTimeDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled ? ' *' : '' }}
                              </span>
                            </template>
                            <span>
                              {{
                                vehicleTrackingSummary[
                                  journey.vehicle.vehicleId
                                ]?.onTimeMessage
                              }}
                              <span v-if="onTimeDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled" style="font-style: italic">
                                </br></br>
                                * This status has been flagged as inaccurate from disputes.
                              </span>
                            </span>
                          </v-tooltip>
                        </td>
                        <td
                          style="display: inline-flex; align-items: center"
                          class="margin-l-2"
                        >
                          <CRIcon
                            :color="
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedOverall
                                ? 'gray'
                                : 'errorNew'
                            "
                            view-box="-4 0 30 24"
                          >
                            {{
                              vehicleTrackingSummary[
                                journey.vehicle.vehicleId
                              ] &&
                              vehicleTrackingSummary[journey.vehicle.vehicleId]
                                .trackedOverall
                                ? 'check'
                                : 'close'
                            }}
                          </CRIcon>
                          <v-tooltip content-class="tracking-summary-tooltip" bottom>
                            <template
                              v-slot:activator="{ on, attrs }"
                              style="font-size: 13px"
                            >
                              <span v-bind="attrs" v-on="on">
                                GPS Data {{ trackedOverallDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled ? ' *' : '' }}
                              </span>
                            </template>
                            <span>
                              {{
                                vehicleTrackingSummary[
                                  journey.vehicle.vehicleId
                                ]?.trackedOverallMessage
                              }}
                              <span v-if="trackedOverallDisputed(journey.vehicle.vehicleId) && isPaymentTermsDisputesEnabled" style="font-style: italic">
                                </br></br>
                                * This status has been flagged as inaccurate from disputes.
                              </span>
                            </span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          referralUnassignedVehicles[provider.reservationId] > 0
                        "
                        style="display: flex; align-items: center"
                      >
                        <img
                          height="14"
                          width="14"
                          :src="require('@/assets/images/tracking-x.png')"
                        />
                        <div class="font-weight-bold text-gray margin-l-2">
                          {{
                            referralUnassignedVehicles[provider.reservationId]
                          }}
                          Unassigned Vehicle(s)
                        </div>
                      </tr>
                    </table>
                  </v-layout>
                </v-layout>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-data-table
          key="itinerary-data-table"
          :items="itineraryItems"
          hide-actions
          disable-pagination
          item-key="stopNumber"
          class="itinerary-table"
          hide-default-header
        >
          <template #items="props">
            <tr
              v-if="
                (selectedTripVehicleGroupId &&
                  props.item.tripVehicleGroupId ===
                    selectedTripVehicleGroupId) ||
                !selectedTripVehicleGroupId
              "
              :id="`${id}-${props.index}-reservation-tracking-stops`"
              :key="`${id}-${props.index}-reservation-tracking-stops`"
              class="w-full"
              style="padding-top: 30px; padding-bottom: 30px"
            >
              <td class="w-54" style="padding: 0px !important">
                <StopIcon
                  :number="props.item.stopNumber"
                  style="margin-left: 5px"
                />
              </td>
              <td
                :key="`${id}-${props.index}-${props.item.address.addressName}-reservation-tracking-tr-expand-stops-address`"
                class="pa-0"
              >
                <p
                  v-if="props.item.address.title"
                  :key="`${id}-${props.index}-${props.item.address.title}-reservation-tracking-tr-expand-stops-address-title`"
                  class="address-title margin-a-0 font-weight-bold"
                  style="min-width: 150px; max-width: 150px"
                >
                  {{ props.item.address.title }}
                </p>
                <tr style="border: none; padding-top: 2px">
                  <td
                    style="
                      min-width: 150px;
                      max-width: 150px;
                      padding-left: 0px !important;
                    "
                  >
                    <span
                      class="margin-a-0"
                      style="vertical-align: middle; max-width: 150px"
                    >
                      {{ props.item.address.addressName }}
                    </span>
                  </td>
                  <td class="time-column" style="padding-right: 0px !important">
                    <div
                      v-if="props.item.dropoffDatetime"
                      class="mb-0 white-space-nowrap"
                    >
                      <b>DO:</b>
                      {{
                        formatDisplayTime(
                          props.item.dropoffDatetime,
                          props.item.timeZone
                        )
                      }}
                    </div>
                    <div
                      v-if="props.item.pickupDatetime"
                      class="mb-0 white-space-nowrap"
                    >
                      <b>PU:</b>
                      {{
                        formatDisplayTime(
                          props.item.pickupDatetime,
                          props.item.timeZone
                        )
                      }}
                    </div>
                  </td>
                </tr>
              </td>
              <table
                border="0"
                cellspacing="0"
                cellpadding="0"
                style="margin-left: 51px"
              >
                <tr
                  v-for="(provider, providerIndex) in providersToShow"
                  :key="providerIndex"
                  class="mt-0 w-full pb-0"
                  style="vertical-align: middle; border: none"
                >
                  <td class="h-min" style="padding-left: 0px !important">
                    <p
                      v-if="selectedProviders.length > 1"
                      class="ma-0"
                      style="width: 150px"
                    >
                      {{ provider.companyName }}
                    </p>
                    <table cellspacing="0">
                      <tr
                        v-for="journeyStop in getJourneyStopsForProvider(
                          props.item.stopId,
                          provider.companyId
                        )"
                        :key="`itinerary-journey-${journeyStop.journeyStopId}`"
                        class="pr-0 pb-0 pt-0"
                        style="
                          border: none;
                          vertical-align: middle;
                          display: block;
                          margin-bottom: 5px !important;
                        "
                      >
                        <td
                          class="font-weight-bold"
                          style="
                            padding-left: 0px !important;
                            min-width: 150px;
                            max-width: 150px;
                            padding-top: 3px !important;
                            padding-right: 6px !important;
                          "
                        >
                          <v-menu
                            top
                            offset-y
                            open-on-hover
                            :close-on-content-click="false"
                            :close-on-click="false"
                            :disabled="
                              !driverInformationByVehicleId[
                                journeyStop.vehicle.vehicleId
                              ]
                            "
                            :nudge-left="45"
                          >
                            <template #activator="{ on }">
                              <span
                                style="
                                  display: inline-flex;
                                  align-items: center;
                                "
                                v-on="on"
                              >
                                <p class="pa-0 ma-0 w-120">
                                  {{
                                    getJourneyVehicleName(journeyStop.vehicle)
                                  }}
                                </p>
                                <span
                                  class="my-0 mx-2 border-radius-round"
                                  style="
                                    width: 13px !important;
                                    height: 13px !important;
                                    flex-shrink: 0;
                                    display: flex;
                                  "
                                  :style="`background-color: ${getColorForJourney(
                                    journeyStop.journeyId
                                  )};`"
                                ></span>
                              </span>
                            </template>
                            <v-list
                              style="background-color: #717171"
                              class="border-radius-2"
                              dense
                            >
                              <v-list-tile
                                v-if="
                                  driverInformationByVehicleId[
                                    journeyStop.vehicle.vehicleId
                                  ] &&
                                  driverInformationByVehicleId[
                                    journeyStop.vehicle.vehicleId
                                  ].length > 1
                                "
                              >
                                <v-list-tile-title
                                  class="h-min text-white"
                                  style="
                                    text-decoration: underline;
                                    font-size: 11px;
                                  "
                                >
                                  <p
                                    class="ma-0 cursor-pointer w-min"
                                    @click="
                                      copyAllDriverInfo(
                                        journeyStop.vehicle.vehicleId
                                      )
                                    "
                                  >
                                    Copy All
                                  </p>
                                </v-list-tile-title>
                              </v-list-tile>
                              <v-list-tile
                                v-for="(driver,
                                driverIndex) in driverInformationByVehicleId[
                                  journeyStop.vehicle.vehicleId
                                ]"
                                :key="driverIndex"
                                style="
                                  height: min-content;
                                  white-space: space-between;
                                "
                              >
                                <v-list-tile-title
                                  class="h-min font-12 text-white"
                                >
                                  {{ `${driver.name} - ${driver.phoneNumber}` }}
                                </v-list-tile-title>
                                <v-list-tile-action
                                  class="h-min text-white"
                                  style="justify-content: end"
                                >
                                  <CRIcon
                                    width="13"
                                    height="13"
                                    @click.native="copyDriverInfo(driver)"
                                  >
                                    copy
                                  </CRIcon>
                                </v-list-tile-action>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </td>
                        <td
                          v-if="
                            showStopStatuses &&
                            stopStatusLabels[journeyStop.journeyId] &&
                            stopStatusLabels[journeyStop.journeyId][
                              journeyStop.stop.stopId
                            ]
                          "
                          style="
                            padding-left: 6px !important;
                            min-width: 195px;
                            max-width: 195px;
                            padding-top: 3px !important;
                          "
                        >
                          <span class="font-weight-bold white-space-nowrap">
                            {{
                              getTimeLabel(
                                journeyStop.journeyId,
                                journeyStop.stop.stopId
                              )
                            }}
                          </span>
                        </td>
                        <td
                          v-if="showStopStatuses"
                          style="
                            padding-right: 0px !important;
                            padding-left: 0px !important;
                            width: 80px;
                          "
                        >
                          <v-chip
                            v-if="
                              stopStatusLabels[journeyStop.journeyId] &&
                              stopStatusLabels[journeyStop.journeyId][
                                journeyStop.stop.stopId
                              ]
                            "
                            small
                            label
                            disabled
                            class="ml-0 status-chip"
                            :style="`background-color: ${generatedStatusChipClass(
                              stopStatusLabels[journeyStop.journeyId][
                                journeyStop.stop.stopId
                              ]
                            )}; color: ${
                              stopStatusLabels[journeyStop.journeyId][
                                journeyStop.stop.stopId
                              ] === 'EN ROUTE'
                                ? $cr.theme.grayDark
                                : 'white'
                            }`"
                          >
                            {{
                              stopStatusLabels[journeyStop.journeyId][
                                journeyStop.stop.stopId
                              ]
                            }}
                          </v-chip>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </tr>
          </template>
        </v-data-table>
      </v-layout>
    </v-layout>
    <v-layout ref="tracking-map" class="map-cont" position-relative>
      <v-layout row class="top-left__container">
        <slot name="top-left"></slot>
        <v-flex>
          <v-btn
            v-if="showLegend"
            v-show="isTrackingVisible"
            :id="`${id}-reservation-map-button-toggle-legend`"
            :class="`drop-shadow legend-btn__${toggleDataTypes ? 'on' : 'off'}`"
            solo
            flat
            :disabled="currentSelectedVehicleJourneys.length === 0"
            @click="toggleDataTypes = !toggleDataTypes"
          >
            Data Type
          </v-btn>
          <v-flex
            v-show="toggleDataTypes"
            class="map-container map-container--legend pt-0 pb-0"
          >
            <v-list
              v-for="(journeysInGroup,
              tripVehicleGroupId,
              vi) in journeysByTripVehicleGroup"
              :key="`${vi}-tvg`"
            >
              <v-list-item
                v-for="(item, mi) in journeysInGroup"
                :key="`journey--${mi}`"
                style="display: flex"
                :style="
                  !vehiclesWithTrackingHistory[item.vehicle.vehicleId]
                    ? 'height: 40px; align-items: flex-end'
                    : 'align-items: flex-start'
                "
              >
                <v-list-tile-content
                  class="legend-list-item max-w-120 min-w-120 padding-r-0"
                  :style="{
                    'margin-top':
                      mi > 0 ||
                      vehiclesWithTrackingHistory[item.vehicle.vehicleId]
                        ? '9px'
                        : '',
                  }"
                >
                  <span
                    class="max-w-88 min-w-88"
                    style="display: inline-block; overflow-wrap: break-word"
                  >
                    {{ getJourneyVehicleName(item.vehicle) }}
                  </span>
                  <span
                    class="legend-list-box"
                    :style="`background-color: ${getColorForJourney(
                      item.journeyId
                    )};`"
                  ></span>
                </v-list-tile-content>
                <v-layout
                  v-if="vehiclesWithTrackingHistory[item.vehicle.vehicleId]"
                  column
                  style="align-items: start"
                >
                  <v-switch
                    v-if="hasELDData[item.vehicle.vehicleId]"
                    :key="`eld-data-switch--${mi}`"
                    v-model="highlightedHistories"
                    color="primary"
                    :value="`${item.vehicle.vehicleId}-eld`"
                    style="display: flex; justify-content: flex-end"
                    :inset="true"
                    compact
                    hide-details
                    @change="toggleEld(item.vehicle.vehicleId)"
                  >
                    <template #label>
                      <span class="font-14">
                        {{ vehicleEldTypes[item.vehicle.vehicleId] }}
                      </span>
                    </template>
                  </v-switch>
                  <v-switch
                    v-if="hasDriverAppData[item.vehicle.vehicleId]"
                    :key="`driver-app-data-switch--${mi}`"
                    v-model="highlightedHistories"
                    color="primary"
                    :value="`${item.vehicle.vehicleId}-driverApp`"
                    style="display: flex; justify-content: flex-end"
                    :inset="true"
                    compact
                    hide-details
                    @change="toggleDriverApp(item.vehicle.vehicleId)"
                  >
                    <template #label>
                      <span class="font-14">Driver App</span>
                    </template>
                  </v-switch>
                  <v-switch
                    v-if="hasBusifyData[item.vehicle.vehicleId]"
                    :key="`busify-data-switch--${mi}`"
                    v-model="highlightedHistories"
                    color="primary"
                    :value="`${item.vehicle.vehicleId}-busify`"
                    style="display: flex; justify-content: flex-end"
                    :inset="true"
                    compact
                    hide-details
                    @change="toggleBusify(item.vehicle.vehicleId)"
                  >
                    <template #label>
                      <span class="font-14">Busify</span>
                    </template>
                  </v-switch>
                </v-layout>
              </v-list-item>
            </v-list>
          </v-flex>
        </v-flex>
      </v-layout>
      <div class="top-right__container">
        <v-flex>
          <v-btn
            v-if="showLegend"
            :class="`drop-shadow legend-btn__${showComments ? 'on' : 'off'} ${
              !(
                showLegend &&
                isTrackingVisible &&
                !!Object.keys(vehiclesWithTrackingHistory).length
              )
                ? 'mr-0'
                : ''
            }`"
            icon
            color="white"
            @click="showComments = !showComments"
          >
            <CRIcon height="24" width="24" view-box="0 0 22 23">
              edit_note
            </CRIcon>
          </v-btn>
          <v-flex
            v-show="showComments"
            class="map-container--legend pt-1 pb-1 pl-2 pr-2 border-radius-8 background-white"
            style="width: 240px"
            :style="
              !(
                showLegend &&
                isTrackingVisible &&
                !!Object.keys(vehiclesWithTrackingHistory).length
              )
                ? 'margin-left: -190px'
                : ''
            "
          >
            <v-textarea
              v-model="newComment"
              class="mt-2 mb-1"
              style="font-size: 13px"
              rows="5"
              flat
              placeholder="Add a Reservation Comment"
              solo
              hide-details
            />
            <span
              class="cursor-pointer text-primary mb-1"
              style="float: right; font-size: 13px"
              @click.stop="addComment"
            >
              Add
            </span>
            <v-flex v-if="comments.length > 0" style="margin-top: 28px">
              <v-divider class="mb-0" />
              <div class="comments-list-container">
                <div class="comments-list">
                  <div
                    v-for="(comment, commentIndex) in comments"
                    :key="`comment--${commentIndex}`"
                    class="comment-container"
                  >
                    <span class="comment-author">
                      {{ commentAuthor(comment) }}
                    </span>
                    <br />
                    <span class="comment-body">{{ comment.comment }}</span>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-flex>
        </v-flex>
        <v-checkbox
          v-if="
            showLegend &&
            isTrackingVisible &&
            !!Object.keys(vehiclesWithTrackingHistory).length
          "
          v-model="showAsDataPoints"
          label="Show as Data Points"
          class="data-points-checkbox"
          :class="`drop-shadow legend-btn__${showAsDataPoints ? 'on' : 'off'}`"
          :ripple="false"
          hide-details
        />
        <v-btn
          v-if="!isLoading"
          icon
          color="white"
          :class="`drop-shadow legend-btn__off`"
          @click="refreshWithUpdate"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <slot name="top-right" :isLoading="isLoading"></slot>
      </div>
      <div class="bottom-middle__container">
        <v-btn
          v-if="isTrackingVisible && showLegend"
          color="white"
          class="mb-0"
          :class="`expand-timeline-button${
            showingHistoryFilter ? '__collapse' : '__expand'
          }`"
          :disabled="!Object.keys(vehiclesWithTrackingHistory).length"
          @click="showingHistoryFilter = !showingHistoryFilter"
        >
          <v-icon v-if="showingHistoryFilter">keyboard_arrow_down</v-icon>
          <span
            v-else
            class="font-16"
            style="display: inline-flex; align-items: center"
          >
            Timeline Slider
            <v-icon>keyboard_arrow_up</v-icon>
          </span>
        </v-btn>
        <v-expand-transition>
          <ReservationTrackingMapHistoryFilter
            v-if="
              isTrackingVisible &&
              !!Object.keys(vehiclesWithTrackingHistory).length &&
              showLegend
            "
            v-show="showingHistoryFilter"
            v-model="historicalMarkersRange"
            style="width: 59vw"
            :vehicle-time="vehicleTime"
            :historical-data="historicalData"
            :start-date="startDate"
            :end-date="endDate"
            :zone="firstTimezone"
            @update-vehicle-time="updateVehicleTime"
            @update-slider-time="updateSliderTime"
          />
        </v-expand-transition>
      </div>

      <div
        v-if="isLoading"
        class="w-full h-full overflow-hidden"
        style="
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <v-progress-circular
          indeterminate
          :size="200"
          color="primary"
        ></v-progress-circular>
      </div>
      <GmapMap
        v-show="!isLoading"
        ref="gMap"
        :center="mapCenter"
        :options="{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          styles: mapStyles,
        }"
        :zoom="defaultZoom"
        map-type-id="roadmap"
        class="w-full h-full overflow-hidden"
      >
        <template
          v-if="
            (trackingReservationStatus === 'FINISHED' || showLegend) &&
            !showAsDataPoints
          "
        >
          <GmapPolyline
            v-for="(historicalMarker, z) in visibleHistoricalMarkers"
            :key="`historical-marker--${z}`"
            :path="historicalMarker.path"
            :options="historicalMarker"
          ></GmapPolyline>
        </template>
        <GmapMarker
          v-for="(stopMarker, z) in visibleStopMarkers"
          :key="`stop-marker--${z}`"
          :position="stopMarker.position"
          :clickable="true"
          :icon="stopMarker.icon"
          @click="stopMarker.isWindowOpen = !stopMarker.isWindowOpen"
        >
          <GmapInfoWindow
            :position="stopMarker.position"
            :opened="stopMarker.isWindowOpen"
          >
            <StopInfoWindow :stop="stopMarker.stop" :timezone="firstTimezone" />
          </GmapInfoWindow>
        </GmapMarker>
        <GmapMarker
          v-for="(garageMarker, z) in visibleGarageMarkers"
          :key="`garage-marker--${z}`"
          :position="garageMarker.position"
          :clickable="true"
          :icon="garageMarker.icon"
          @click="garageMarker.isWindowOpen = !garageMarker.isWindowOpen"
        >
          <GmapInfoWindow
            :position="garageMarker.position"
            :opened="garageMarker.isWindowOpen"
          >
            <StopInfoWindow :stop="garageMarker.stop" is-garage />
          </GmapInfoWindow>
        </GmapMarker>
        <GmapMarker
          v-for="(vehicleMarker, z) in visibleVehicleMarkers"
          :key="`vehicle-marker--${z}`"
          :position="vehicleMarker.position"
          :clickable="true"
          :icon="
            blink && vehicleMarker.blinkIcon
              ? vehicleMarker.blinkIcon
              : vehicleMarker.icon
          "
          @mouseover="vehicleMarker.isBusWindowOpen = true"
          @mouseout="mouseOutVehicleMarker(vehicleMarker)"
        >
          <GmapInfoWindow
            :position="vehicleMarker.position"
            :opened="vehicleMarker.isBusWindowOpen"
          >
            <BusInfoWindow
              :reservation-id="vehicleMarker.reservationId"
              :vehicle="vehicleMarker.vehicle"
              :current-driver="vehicleMarker.driver"
              :company-name="vehicleMarker.companyName"
              :etas="vehicleMarker.etas"
              :timezone="firstTimezone"
              @mouseover="vehicleMarker.hoverInfoWindow = true"
              @mouseleave="mouseLeaveInfoWindow(vehicleMarker)"
            />
          </GmapInfoWindow>
        </GmapMarker>
      </GmapMap>
    </v-layout>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import { DateTime } from 'luxon'
import { gmapApi } from 'vue2-google-maps'
import GmapMarker from 'vue2-google-maps/dist/components/marker'
import GmapInfoWindow from 'vue2-google-maps/dist/components/infoWindow'
import { getTrackingHistoryV2 } from '@/services/reservations'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import { authComputed } from '@/state/helpers'
import { mapStyles } from '@/components/mapStyles'
import BusIcon from '@/components/BusIcon.vue'
import StopIcon from '@/components/StopIcon.vue'
import BusInfoWindow from '@/components/BusInfoWindow.vue'
import StopInfoWindow from '@/components/StopInfoWindow.vue'
import ReservationTrackingMapHistoryFilter from '@/components/ReservationTrackingMapHistoryFilter.vue'
import ReservationTrackingMapProviderSelector from '@/components/ReservationTrackingMapProviderSelector.vue'
import ReservationTrackingMapVehicleGroupSelector from '@/components/ReservationTrackingMapVehicleGroupSelector.vue'
import ReservationTrackingMapVehicleJourneySelector from '@/components/ReservationTrackingMapVehicleJourneySelector.vue'
import {
  numMinutesBetweenDateTimes,
  numDaysBetweenDateTimes,
  ianaZoneToOffsetName,
} from '@/utils/time'
import { deepClone } from '@/utils/deepClone'
import {
  ReservationStatus,
  ReservationTrackingAllocation,
  SplitFeatureFlag,
} from '@/utils/enum'
import { mapGetters, mapActions, mapState } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import eld from '@/services/eld'
import * as logger from '@/utils/logger'
import tracking from '@/services/tracking'

let BusIconClass = Vue.extend(BusIcon)
let StopIconClass = Vue.extend(StopIcon)

export default {
  components: {
    GmapInfoWindow,
    GmapMarker,
    StopIcon,
    BusInfoWindow,
    StopInfoWindow,
    ReservationTrackingMapHistoryFilter,
    ReservationTrackingMapVehicleGroupSelector,
    ReservationTrackingMapProviderSelector,
    ReservationTrackingMapVehicleJourneySelector,
  },
  props: {
    showLegend: { type: Boolean, default: false },
    reservationId: { type: Number, default: () => null },
    reservationStatus: { type: String, default: 'finished' },
    journeys: { type: Array, default: () => [] },
    startDate: { type: String, default: null },
    minRefresh: { type: Number, default: -300 },
    stops: { type: Array, required: true },
    tripVehicleGroups: { type: Array, default: () => [] },
    referredProviders: { type: Array, default: () => [] },
    fullTripVehicleGroups: { type: Array, default: () => [] },
    itineraryItems: { type: Array, default: () => null },
    tripAssignments: { type: Array, default: () => [] },
  },
  data() {
    return {
      colors: [
        { name: 'purple', hex: '#6a63e8' },
        { name: 'green', hex: '#7cd074' },
        { name: 'orange', hex: '#ea7721' },
        { name: 'red', hex: '#a32b1f' },
        { name: 'blue', hex: '#253f71' },
        { name: 'yellow', hex: '#ffbd00' },
        { name: 'dark-green', hex: '#215c4a' },
        { name: 'pink', hex: '#ce52b3' },
      ],
      debounce: null,
      lastCalculated: null,
      toggleDataTypes: false,
      showComments: false,
      comments: [],
      newComment: '',
      defaultZoom: 9,
      adjustedJourneys: [],
      isLoading: false,
      trackingSummaryPanel: null,
      referralTrackingValues: {},
      isAcceleratedPaymentsEnabled: false,
      isPaymentTermsDisputesEnabled: false,

      // history
      showingHistoryFilter: true,
      historicalData: [],
      historicalMarkers: [],
      highlightedHistories: [],
      vehicleTime: 0,
      hasDriverAppData: {},
      hasBusifyData: {},
      hasELDData: {},
      trackingJourneyDataList: [],
      showAsDataPoints: false,
      historicalDataPoints: [],
      historicalDataPointMarkers: [],
      sliderRange: [],

      // vehicles
      driverInformationByVehicleId: {},
      vehicleMarkers: [],
      highlightedVehicles: new Map(),
      allTrackers: [],
      liveTrackers: [],
      trackingAllocation: null,
      vehiclesWithTrackingHistory: {},
      vehicleEldTypes: {},
      selectedTripVehicleGroupId: null,
      currentSelectedVehicleJourneys: [],
      selectedProviders: [],
      blinkInterval: null,
      blink: false,
      vehicleTrackingSummary: {},
      referralUnassignedVehicles: {},

      // stops
      stopMarkers: [],
      stopStatusLabels: {},
      firstStopDetails: {},
      journeyStopStatuses: new Map(),

      // garage
      garages: [],
      garageMarkers: [],

      // map
      map: null,
      mapCenter: { lat: 35.5, lng: -98.35 },
      bounds: null,
      mapStyles: [
        ...mapStyles,
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }
  },
  computed: {
    google: gmapApi,
    ...authComputed,
    ...mapGetters({
      distanceAndDuration: 'trackingDevices/distanceAndDuration',
    }),
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    providersToShow() {
      return this.selectedTripVehicleGroupId
        ? this.selectedProviders.filter((p) =>
            p.tripVehicleGroups.some(
              (tvg) =>
                tvg.tripVehicleGroupId === this.selectedTripVehicleGroupId
            )
          )
        : this.selectedProviders
    },
    showStopStatuses() {
      return (
        this.trackingReservationStatus === ReservationStatus.Started ||
        this.trackingReservationStatus === ReservationStatus.Finished
      )
    },
    stale() {
      return (
        !this.lastCalculated ||
        this.lastCalculated.diffNow('seconds').seconds < this.minRefresh
      )
    },
    now() {
      return DateTime.fromJSDate(new Date())
        .setZone(this.currentUser.timeZone)
        .toJSDate()
    },
    endDate() {
      return this.stops
        ?.flatMap((s) => [s.pickupDatetime, s.dropoffDatetime])
        .filter((t) => !!t)
        .sort((a, b) => DateTime.fromISO(a) > DateTime.fromISO(b))
        .pop()
    },
    firstTimezone() {
      return this.stops?.filter((stop) => stop?.address?.timeZone)[0]?.address
        ?.timeZone
    },
    zonedStartDate() {
      if (this.firstTimezone) {
        return DateTime.fromISO(this.startDate, {
          zone: this.firstTimezone,
        }).toJSDate()
      } else {
        return DateTime.fromISO(this.startDate).toJSDate()
      }
    },
    lastTimezone() {
      const filteredStops = this.stops?.filter((stop) => stop.address?.timeZone)
      return filteredStops?.[filteredStops.length - 1]?.address?.timeZone
    },
    zonedEndDate() {
      if (this.lastTimezone) {
        return DateTime.fromISO(this.endDate, {
          zone: this.lastTimezone,
        }).toJSDate()
      } else {
        return DateTime.fromISO(this.endDate).toJSDate()
      }
    },
    vehicleIds() {
      return this.journeys.map((journey) => {
        if (journey?.vehicle?.vehicleId) {
          return journey.vehicle.vehicleId
        }
      })
    },
    allJourneyStops() {
      return this.journeys.reduce((allStops, journey) => {
        if (journey.journeyStops.length > 0) {
          allStops.push(
            ...journey.journeyStops.map((journeyStop) => {
              return { ...journeyStop.stop, reached: journeyStop.reached }
            })
          )
        }
        return allStops
      }, [])
    },
    journeysByTripVehicleGroup() {
      let journeysByTripVehicleGroup = {}
      const filteredJourneys = this.adjustedJourneys.filter((journey) =>
        this.currentSelectedVehicleJourneys.some(
          (vj) => vj.vehicle.vehicleId === journey.vehicle.vehicleId
        )
      )
      for (let journey of filteredJourneys) {
        let id = journey.vehicle?.tripVehicleGroupId || -1
        if (!journeysByTripVehicleGroup[id]) {
          journeysByTripVehicleGroup[id] = []
        }
        journeysByTripVehicleGroup[id].push(journey)
      }
      return journeysByTripVehicleGroup
    },
    simulatedTripAssignmentVehicles() {
      return this.tripAssignments
        .map((tripAssignment) => tripAssignment.vehicle)
        .filter((vehicle) => vehicle.simulated)
        .reduce((map, vehicle) => {
          map[vehicle.vehicleId] = vehicle
          return map
        }, {})
    },
    currentJourneysBySelectedTripVehicleGroup() {
      if (this.selectedTripVehicleGroupId) {
        return this.adjustedJourneys.filter(
          (journey) =>
            this.selectedTripVehicleGroupId ===
            journey.vehicle.tripVehicleGroupId
        )
      }
      if (this.showLegend) {
        return this.adjustedJourneys.filter((journey) =>
          this.selectedProviders.some(
            (provider) => provider.companyId === journey.vehicle.companyId
          )
        )
      }
      return this.adjustedJourneys
    },
    localCurrentTripVehicleGroups() {
      let tripVehicleGroups = []
      if (this.fullTripVehicleGroups) {
        for (const tripVehicleGroup of this.fullTripVehicleGroups) {
          const provider = this.selectedProviders.find((provider) =>
            provider.tripVehicleGroups.some(
              (tvg) =>
                tvg.tripVehicleGroupId === tripVehicleGroup.tripVehicleGroupId
            )
          )
          if (provider) {
            tripVehicleGroups.push(
              deepClone(
                provider.tripVehicleGroups.find(
                  (tvg) =>
                    tvg.tripVehicleGroupId ===
                    tripVehicleGroup.tripVehicleGroupId
                )
              )
            )
          }
        }
      }
      return tripVehicleGroups
    },
    reservationStartsWithinAnHour() {
      if (this.now == null || this.zonedStartDate === null) {
        return false
      }
      return numMinutesBetweenDateTimes(this.now, this.zonedStartDate) <= 60
    },
    reservationStartsWithinThreeHours() {
      if (this.now == null || this.zonedStartDate === null) {
        return false
      }
      return numMinutesBetweenDateTimes(this.now, this.zonedStartDate) <= 180
    },
    reservationEndedWithinTwoHoursAgo() {
      if (this.now == null || this.zonedEndDate === null) {
        return false
      }
      return numMinutesBetweenDateTimes(this.zonedEndDate, this.now) <= 120
    },
    isPreTripLiveVehicleLocationVisible() {
      return (
        this.trackingReservationStatus === ReservationStatus.Upcoming &&
        this.reservationStartsWithinThreeHours
      )
    },
    isPostTripLiveVehicleLocationVisible() {
      return (
        this.trackingReservationStatus === ReservationStatus.Finished &&
        this.reservationEndedWithinTwoHoursAgo
      )
    },
    isReservationUpcoming() {
      return this.reservationStatus === 'upcoming'
    },
    isReservationStarted() {
      return this.reservationStatus === 'started'
    },
    isReservationFinished() {
      return this.reservationStatus === 'finished'
    },
    isLiveVehicleLocationVisible() {
      return (
        (this.isReservationStarted || this.isReservationUpcoming) &&
        this.reservationStartsWithinAnHour
      )
    },
    isTrackingVisible() {
      return (
        this.isPreTripLiveVehicleLocationVisible ||
        this.isLiveVehicleLocationVisible ||
        this.isReservationFinished
      )
    },
    shouldShowTrackingTag() {
      if (this.zonedStartDate === null) {
        return null
      }
      if (numDaysBetweenDateTimes(this.now, this.zonedStartDate) > 7) {
        return false
      }
      return true
    },
    trackingReservationStatus() {
      if (this.zonedStartDate === null || this.zonedEndDate === null) {
        return null
      }

      if (
        numDaysBetweenDateTimes(this.now, this.zonedStartDate) < 7 &&
        numMinutesBetweenDateTimes(this.now, this.zonedStartDate) > 60
      ) {
        return ReservationStatus.Upcoming
      }
      if (
        this.reservationStartsWithinAnHour &&
        numMinutesBetweenDateTimes(this.zonedEndDate, this.now) <= 60
      ) {
        return ReservationStatus.Started
      }
      if (numMinutesBetweenDateTimes(this.zonedEndDate, this.now) > 60) {
        return ReservationStatus.Finished
      }
      return null
    },
    historicalMarkersRange() {
      const startDate = Math.max(
        Math.min(
          ...this.historicalData.map((hd) =>
            DateTime.fromISO(hd.reportedOn).toSeconds()
          )
        ),
        DateTime.fromISO(this.startDate).minus({ hours: 3 }).toSeconds()
      )
      const endDate = Math.min(
        Math.max(
          ...this.historicalData.map(
            (hd) => DateTime.fromISO(hd.reportedOn).toSeconds(),
            DateTime.fromISO(this.endDate).plus({ hours: 2 }).toSeconds()
          )
        ),
        DateTime.local().toSeconds()
      )
      return [Math.round(startDate), Math.round(endDate)]
    },
    visibleStopMarkers() {
      return this.stopMarkers.filter((marker) => marker.isVisible)
    },
    visibleGarageMarkers() {
      return this.garageMarkers.filter((marker) => marker.isVisible)
    },
    visibleVehicleMarkers() {
      return this.vehicleMarkers.filter((v) => v.isVisible)
    },
    visibleHistoricalMarkers() {
      return this.historicalMarkers.filter(
        (obj) =>
          (this.currentSelectedVehicleJourneys.some(
            (vj) => vj.vehicle.vehicleId === obj.vehicleId
          ) ||
            !this.showLegend) &&
          (obj.isVisible ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-driverApp`) &&
              obj.eldType === 'COACHRAIL') ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-busify`) &&
              obj.eldType === 'BUSIFY') ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-eld`) &&
              obj.eldType !== 'COACHRAIL' &&
              obj.eldType !== 'BUSIFY'))
      )
    },
    visibleHistoricalDataPoints() {
      return this.historicalDataPoints.filter(
        (obj) =>
          this.currentSelectedVehicleJourneys.some(
            (vj) => vj.vehicle.vehicleId === obj.vehicleId
          ) &&
          (obj.isVisible ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-driverApp`) &&
              obj.eldType === 'COACHRAIL') ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-busify`) &&
              obj.eldType === 'BUSIFY') ||
            (this.highlightedHistories.includes(`${obj.vehicleId}-eld`) &&
              obj.eldType !== 'COACHRAIL' &&
              obj.eldType !== 'BUSIFY'))
      )
    },
    currentSelectedJourneyStopsByStopId() {
      const currentSelectedJourneysByStopId = {}
      for (const journey of this.currentSelectedVehicleJourneys) {
        for (const journeyStop of journey.journeyStops) {
          if (
            !currentSelectedJourneysByStopId.hasOwnProperty(
              journeyStop.stop.stopId
            )
          ) {
            currentSelectedJourneysByStopId[journeyStop.stop.stopId] = []
          }
          journeyStop.vehicle = journey.vehicle
          if (
            !currentSelectedJourneysByStopId[journeyStop.stop.stopId].some(
              (j) => j.vehicle?.vehicleId === journeyStop?.vehicle?.vehicleId
            )
          ) {
            currentSelectedJourneysByStopId[journeyStop.stop.stopId].push({
              ...journeyStop,
              journeyId: journey.journeyId,
            })
          }
        }
      }
      return currentSelectedJourneysByStopId
    },
  },
  watch: {
    journeys: {
      deep: true,
      async handler() {
        this.clearMap()
        await this.loadJourneyData()
        this.loadMap()
      },
    },
    showAsDataPoints(value) {
      if (value) {
        this.makeHistoricalDataPointMarkers()
      } else {
        this.hideDataPointMarkers()
      }
    },
    referredProviders(value) {
      this.selectedProviders = value.filter(
        (provider) => provider.computedName !== 'All'
      )
      if (this.localCurrentTripVehicleGroups.length > 0) {
        this.selectedTripVehicleGroupId = this.localCurrentTripVehicleGroups?.[0].tripVehicleGroupId
      }
      this.selectedJourneys = this.adjustedJourneys
    },
    tripAssignments() {
      this.loadDriverInformation()
    },
  },
  async mounted() {
    this.isLoading = true
    this.selectedProviders = this.referredProviders.filter(
      (provider) => provider.computedName !== 'All'
    )
    if (this.localCurrentTripVehicleGroups.length > 0) {
      this.selectedTripVehicleGroupId = this.localCurrentTripVehicleGroups?.[0].tripVehicleGroupId
    }
    this.selectedJourneys = this.adjustedJourneys
    await this.loadDriverInformation()
    await this.loadJourneyData()
    await this.loadMap()
    await this.loadComments()
    this.isAcceleratedPaymentsEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled',
      SplitFeatureFlag.AcceleratedPayments
    )
    this.isPaymentTermsDisputesEnabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.PaymentTermsDisputes)
    if (this.isReservationFinished && this.isAcceleratedPaymentsEnabled) {
      await this.loadTrackingSummary()
    }
    this.isLoading = false

    EventBus.$on('update-sync-time', (vehicleIds) => {
      vehicleIds.forEach((id) => {
        const tracker = this.liveTrackers.find((d) => d.vehicleId === id)
        if (tracker?.lastReportTime) {
          const vehicleMarker = this.vehicleMarkers.find((marker) => {
            return marker.vehicle.vehicleId == id
          })
          vehicleMarker.vehicle.lastSyncTime = tracker.lastReportTime
        }
      })
    })
  },
  beforeDestroy() {
    clearInterval(this.blinkInterval)
  },
  methods: {
    ...mapActions({
      getTrackingDevicesByVehicleIdsV2:
        'trackingDevices/getTrackingDevicesByVehicleIdsV2',
      getGaragesByVehicleIds: 'vehicles/getGaragesByVehicleIds',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    mouseLeaveInfoWindow(vehicleMarker) {
      vehicleMarker.hoverInfoWindow = false
      vehicleMarker.isBusWindowOpen = false
    },
    mouseOutVehicleMarker(vehicleMarker) {
      setTimeout(() => {
        if (!vehicleMarker.hoverInfoWindow) {
          vehicleMarker.isBusWindowOpen = false
        }
      }, 200)
    },
    formatDisplayTime(time, timeZone) {
      if (!time) {
        return '--:--'
      }
      const datetime = DateTime.fromISO(time, { zone: timeZone })
      return datetime.toFormat('M/dd/yyyy t ZZZZ')
    },
    trackedArrivalDisputed(vehicleId) {
      return this.vehicleTrackingSummary[vehicleId].trackedArrivalDisputed
    },
    onTimeDisputed(vehicleId) {
      return this.vehicleTrackingSummary[vehicleId].onTimeDisputed
    },
    trackedOverallDisputed(vehicleId) {
      return this.vehicleTrackingSummary[vehicleId].trackedOverallDisputed
    },
    getJourneyStopsForProvider(stopId, companyId) {
      return this.currentSelectedJourneyStopsByStopId[stopId]?.filter(
        (s) => s.vehicle.companyId === companyId
      )
    },
    getJourneysForProvider(companyId) {
      return this.journeys?.filter((j) => j.vehicle.companyId === companyId)
    },
    copyAllDriverInfo(vehicleId) {
      let text = ''
      for (const driver of this.driverInformationByVehicleId[vehicleId]) {
        if (text.length) {
          text += ', '
        }
        text += driver.name
        if (driver.phoneNumber !== 'N/A') {
          text += ` - ${driver.phoneNumber}`
        }
      }
      navigator.clipboard.writeText(text)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Driver info copied to your clipboard',
      })
    },
    copyDriverInfo(driver) {
      let text = driver.name
      if (driver.phoneNumber !== 'N/A') {
        text += ` - ${driver.phoneNumber}`
      }
      navigator.clipboard.writeText(text)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Driver info copied to your clipboard',
      })
    },
    getTimeLabel(journeyId, stopId) {
      const statusLabel = this.stopStatusLabels[journeyId][stopId]
      const status = this.journeyStopStatuses
        .get(journeyId)
        ?.find((s) => s.stopId === stopId)
      if (!status || !statusLabel) {
        return '--:--'
      }

      const timezone =
        this.stops.find((s) => s.stopId === stopId)?.address?.timeZone ||
        this.firstTimezone
      switch (statusLabel) {
        case 'EXPECTED ON TIME':
        case 'EXPECTED LATE': {
          const firstStopStatus = this.firstStopDetails[journeyId]
          return `ETA: ${this.formatDisplayTime(firstStopStatus.eta, timezone)}`
        }
        case 'ON TIME':
        case 'LATE':
        case 'ARRIVED':
          return this.formatDisplayTime(status.arrivedTimestamp, timezone)
        case 'EN ROUTE':
          return this.formatDisplayTime(status.enRouteTimestamp, timezone)
        case 'UNDETECTED':
          return '--:--'
        default:
          return '--:--'
      }
    },
    setStopStatuses() {
      const statusLabels = {}
      for (const journey of this.adjustedJourneys) {
        const journeyStopsForJourney = this.allJourneyStops.filter((js) =>
          journey.stops.some((stop) => stop.stopId === js.stopId)
        )
        const stopStatusesForJourney = this.journeyStopStatuses.get(
          journey.journeyId
        )
        statusLabels[journey.journeyId] = {}
        for (const journeyStop of journeyStopsForJourney) {
          const vehicleId = journey.vehicle.vehicleId
          if (!this.allTrackers.some((t) => t.vehicleId === vehicleId)) {
            statusLabels[journey.journeyId][journeyStop.stopId] = 'NO TRACKING'
            continue
          }

          const status = stopStatusesForJourney?.find(
            (s) => s.orderIndex === journeyStop.orderIndex
          )

          if (!status) {
            statusLabels[journey.journeyId][journeyStop.stopId] = null
            continue
          }

          const index = stopStatusesForJourney?.indexOf(status)
          if (index === 0) {
            if (status.skippedTimestamp) {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'UNDETECTED'
              continue
            }
            const firstStopDetails = this.firstStopDetails[journey.journeyId]
            if (
              this.trackingReservationStatus === ReservationStatus.Started &&
              !status.complete
            ) {
              if (firstStopDetails?.status === 'ON_TIME') {
                statusLabels[journey.journeyId][journeyStop.stopId] =
                  'EXPECTED ON TIME'
              } else if (firstStopDetails?.status === 'LATE') {
                statusLabels[journey.journeyId][journeyStop.stopId] =
                  'EXPECTED LATE'
              } else {
                statusLabels[journey.journeyId][journeyStop.stopId] =
                  'UNDETECTED'
              }
            } else if (status.unknown) {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'UNDETECTED'
            } else if (status.complete && status.arrivedTimestamp) {
              if (
                new Date(status.arrivedTimestamp).getTime() - 1000 * 60 <=
                new Date(status.pickupDatetime).getTime()
              ) {
                statusLabels[journey.journeyId][journeyStop.stopId] = 'ON TIME'
              } else {
                statusLabels[journey.journeyId][journeyStop.stopId] = 'LATE'
              }
            } else if (
              this.trackingReservationStatus === ReservationStatus.Finished
            ) {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'UNDETECTED'
            }
          } else {
            if (status.skippedTimestamp) {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'UNDETECTED'
            } else if (status.complete) {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'ARRIVED'
            } else if (
              this.trackingReservationStatus === ReservationStatus.Started
            ) {
              if (status.enRouteTimestamp) {
                statusLabels[journey.journeyId][journeyStop.stopId] = 'EN ROUTE'
              } else {
                const priorStopStatus = this.journeyStopStatuses.get(
                  journey.journeyId
                )?.[index - 1]
                if (
                  !(
                    priorStopStatus?.complete ||
                    priorStopStatus?.skippedTimestamp ||
                    priorStopStatus?.unknown
                  )
                ) {
                  statusLabels[journey.journeyId][journeyStop.stopId] = null
                } else {
                  statusLabels[journey.journeyId][journeyStop.stopId] = null
                }
              }
            } else {
              statusLabels[journey.journeyId][journeyStop.stopId] = 'UNDETECTED'
            }

            if (
              this.trackingReservationStatus === ReservationStatus.Started &&
              (statusLabels[journey.journeyId][journeyStop.stopId] ===
                'ARRIVED' ||
                statusLabels[journey.journeyId][journeyStop.stopId] ===
                  'EN ROUTE')
            ) {
              for (const stopId in statusLabels[journey.journeyId]) {
                if (statusLabels[journey.journeyId][stopId] === null) {
                  statusLabels[journey.journeyId][stopId] = 'UNDETECTED'
                }
              }
            }
          }
        }
      }
      this.stopStatusLabels = statusLabels
    },
    generatedStatusChipClass(status) {
      switch (status) {
        case 'EXPECTED ON TIME':
        case 'ON TIME':
        case 'ARRIVED':
          return this.$cr.theme.grayDark
        case 'EN ROUTE':
          return this.$cr.theme.grayMidLight
        case 'EXPECTED LATE':
        case 'LATE':
        case 'NO TRACKING':
        case 'UNDETECTED':
          return this.$cr.theme.errorNew
        default:
          return this.$cr.theme.grayDark
      }
    },
    async addComment() {
      if (!(this.newComment && this.newComment.length > 0)) {
        return
      }
      const params = {
        reservation: {
          id: this.reservationId,
        },
        refreshId: this.reservationId,
        note: this.newComment,
      }
      await this.$store.dispatch('reservations/addReservationComment', params)
      this.loadComments()
      this.newComment = ''
    },
    commentAuthor(comment) {
      const commentDateTime = DateTime.fromISO(
        comment?.createdOn
      ).toLocaleString(DateTime.DATETIME_SHORT)
      return `${commentDateTime} by ${comment?.userName}`
    },
    async loadComments() {
      const reservation =
        this.reservationsCache[this.reservationId] ||
        this.reservationsCache[this.$route.params.id]
      if (reservation?.reservationComments) {
        const sortByDate = (a, b) =>
          DateTime.fromISO(a.createdOn) > DateTime.fromISO(b.createdOn) ? -1 : 0
        const commentsOut = deepClone(reservation?.reservationComments)
        commentsOut.sort(sortByDate)
        this.comments = commentsOut
      }
    },
    async loadDriverInformation() {
      for (const provider of this.referredProviders) {
        const assignmentsForProvider = this.tripAssignments.filter(
          (ta) => ta.reservationId === provider.reservationId
        )
        let moreDriversThanVehicles = false
        for (const assignment of assignmentsForProvider) {
          if (assignment.driverAssignments.length > 1) {
            moreDriversThanVehicles = true
            break
          }
        }

        const driversList = []
        for (const assignment of assignmentsForProvider) {
          for (const driverAssignment of assignment.driverAssignments) {
            if (!moreDriversThanVehicles) {
              this.driverInformationByVehicleId[
                assignment.vehicle.vehicleId
              ] = [
                {
                  name: `${driverAssignment.driver.firstName}${
                    driverAssignment.driver.lastName
                      ? ` ${driverAssignment.driver.lastName}`
                      : ''
                  }`,
                  phoneNumber: driverAssignment.driver.phone || 'N/A',
                },
              ]
            } else {
              driversList.push({
                name: `${driverAssignment.driver.firstName}${
                  driverAssignment.driver.lastName
                    ? ` ${driverAssignment.driver.lastName}`
                    : ''
                }`,
                phoneNumber: driverAssignment.driver.phone || 'N/A',
              })
            }
          }
        }
        if (moreDriversThanVehicles) {
          for (const assignment of assignmentsForProvider) {
            this.driverInformationByVehicleId[
              assignment.vehicle.vehicleId
            ] = driversList
          }
        }
      }
    },
    async loadJourneyData() {
      let { data } = await getTripAssignmentsForReservation({
        reservationIds: [this.reservationId],
      })
      const vehicleAssignments = data?.vehicleAssignments
      for (const journey of this.journeys) {
        if (
          !this.adjustedJourneys.some(
            (j) => j.vehicle.vehicleId === journey.vehicle.vehicleId
          )
        ) {
          this.adjustedJourneys.push(deepClone(journey))
        }
      }
      for (const journey of this.adjustedJourneys) {
        journey.stops.forEach((journeyStop) => {
          journeyStop.address = this.stops?.find(
            (stop) => stop.stopId === journeyStop.stopId
          )?.address
        })
        journey.driver = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.driverAssignments?.[0]?.driver

        journey.vehicle.tripVehicleGroupId = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.tripVehicleGroupId

        journey.vehicle.assignedDateTime = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.createdOn
      }
      const resp = await this.getTrackingDevicesByVehicleIdsV2({
        requestBody: {
          vehicleIds: this.vehicleIds,
        },
        prioritizeEld: false,
      })
      this.allTrackers = resp.data.devices
      if (
        this.isLiveVehicleLocationVisible ||
        this.isPreTripLiveVehicleLocationVisible ||
        this.isPostTripLiveVehicleLocationVisible
      ) {
        this.liveTrackers = this.allTrackers.filter((tracker) => {
          if (!tracker?.lastReportTime) {
            return false
          }
          let trackingTime
          if (this.firstTimezone) {
            trackingTime = DateTime.fromISO(tracker.lastReportTime)
              .setZone(this.firstTimezone)
              .toJSDate()
          } else {
            trackingTime = DateTime.fromISO(tracker.lastReportTime).toJSDate()
          }
          return (
            tracker?.lastReportTime &&
            numMinutesBetweenDateTimes(trackingTime, this.zonedStartDate) <= 180
          )
        })
      }
      const res = await this.getGaragesByVehicleIds(this.vehicleIds)
      this.garages = res.data.garages.map((garage) => { return { ...garage } })

      if (this.showLegend) {
        const journeyIds = this.adjustedJourneys?.map((j) => j.journeyId)
        const stopStatusResp = await eld.getStopStatusesForJourneysV2(journeyIds)
        for (const result of stopStatusResp.data.journeyStatuses) {
          this.journeyStopStatuses.set(
            result.journeyId,
            result.journeyStopStatuses
          )
        }
        if (
          this.isPreTripLiveVehicleLocationVisible ||
          this.isLiveVehicleLocationVisible ||
          this.isPostTripLiveVehicleLocationVisible
        ) {
          try {
            const onTimeResp = await eld.getJourneyOnTimeStatus(journeyIds)
            for (const result of onTimeResp.data.data) {
              this.firstStopDetails[result.journeyId] = {
                eta: result.eta,
                status: result.onTimeStatus,
              }
            }
          } catch (e) {
            logger.error(e)
          }
        }
      }

      for (const journey of this.currentJourneysBySelectedTripVehicleGroup) {
        if (
          !this.currentSelectedVehicleJourneys.some(
            (vj) => vj.journeyId === journey.journeyId
          )
        ) {
          this.currentSelectedVehicleJourneys.push(journey)
          await this.highlightedVehicles.set(journey.journeyId, true)
        }
      }

      if (
        this.trackingReservationStatus === ReservationStatus.Started ||
        this.trackingReservationStatus === ReservationStatus.Finished
      ) {
        this.setStopStatuses()
      }
    },
    async loadTrackingSummary() {
      const referralIds = this.referredProviders
        ?.filter((p) => p.computedName !== 'All')
        .map((p) => p.reservationId)
      const response = await tracking.getTrackingSummary(referralIds)
      const trackingSummaries = response?.data?.reservationTrackingSummary
      for (const trackingSummary of trackingSummaries) {
        this.referralUnassignedVehicles[trackingSummary.referralId] =
          trackingSummary.unassignedVehicles
        const fullyTracked = true
        if (trackingSummary.journeyTrackingSummary.length < 1) {
          fullyTracked = false
        }
        for (const summary of trackingSummary.journeyTrackingSummary) {
          this.vehicleTrackingSummary[summary.vehicleId] = summary
          if (
            !(
              summary.onTime &&
              summary.trackedArrival &&
              summary.trackedOverall
            )
          ) {
            fullyTracked = false
          }
        }
        this.referralTrackingValues[trackingSummary.referralId] = fullyTracked
      }
    },
    refreshWithUpdate() {
      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(() => {
        this.clearMap()
        this.loadMap()
      }, 500)
    },
    clearMap() {
      if (this.stale) {
        this.liveTrackers = []
        this.trackingAllocation = null
      }
      this.historicalMarkers = []
      this.historicalDataPoints = []
      this.clearDataPointMarkers()
      this.vehicleMarkers = []
      this.stopMarkers = []
      this.garageMarkers = []
      this.historicalData = []
    },
    async loadMap() {
      if (this.$refs.gMap == null) {
        return
      }

      const map = await this.$refs.gMap.$mapPromise
      this.map = map
      this.bounds = new this.google.maps.LatLngBounds()
      await this.makeMarkers()
      this.showingHistoryFilter = !!Object.keys(
        this.vehiclesWithTrackingHistory
      ).length
      if (
        this.showLegend &&
        this.isTrackingVisible &&
        !!Object.keys(this.vehiclesWithTrackingHistory).length
      ) {
        // adjust map zoom so history filter doesn't obstruct the vehicle/history markers
        const ne = this.bounds.getNorthEast()
        const sw = this.bounds.getSouthWest()

        const lat = sw.lat()
        const diff = lat - ne.lat()
        const newLat =
          lat + (135 * diff) / this.$refs['tracking-map'].clientHeight

        const lng = ne.lng()

        const point = new google.maps.LatLng(newLat, lng)
        this.bounds.extend(point)
      }
      this.map.fitBounds(this.bounds)
      this.lastCalculated = DateTime.local()
    },
    async makeMarkers() {
      for (const journey of this.adjustedJourneys) {
        // Load all vehicle history but only default shown if finished
        if (this.isTrackingVisible) {
          await this.setHistoricalData(journey)
          this.makeHistoricalMarkers(journey)
        }
        await this.makeVehicleMarkers(journey)
      }
      if (this.isTrackingVisible) {
        this.makeHistoricalDataPointMarkers()
      }
      const bounds = new this.google.maps.LatLngBounds()
      for (const historicalMarker of this.historicalMarkers) {
        for (const pos of historicalMarker.path) {
          bounds.extend(new this.google.maps.LatLng(pos.lat, pos.lng))
        }
      }
      this.bounds.union(bounds)
      this.makeStopMarkers()
      // Only show garages/directions/vehicles if started or upcoming within an hour
      if (
        this.isLiveVehicleLocationVisible ||
        this.isPostTripLiveVehicleLocationVisible
      ) {
        this.makeGarageMarkers()
      }
      if (
        this.isPreTripLiveVehicleLocationVisible ||
        this.isLiveVehicleLocationVisible ||
        this.isPostTripLiveVehicleLocationVisible ||
        this.showLegend
      ) {
        this.highlightVehicles()
      }
      clearInterval(this.blinkInterval)
      if (
        this.vehicleMarkers.some((vm) => vm.blinkIcon) &&
        this.showLegend &&
        (this.isPreTripLiveVehicleLocationVisible ||
          this.isPostTripLiveVehicleLocationVisible ||
          this.trackingReservationStatus === ReservationStatus.Upcoming ||
          this.trackingReservationStatus === ReservationStatus.Started)
      ) {
        this.blinkInterval = setInterval(() => {
          this.blink = !this.blink
        }, 1000)
      }
    },
    makeGarageMarkers() {
      this.garages.forEach((garage) => {
        const {
          address: { lat, lng },
        } = garage

        let propsData = {
          status: 'GARAGE',
        }

        let component = new StopIconClass({ propsData })
        component.$mount()

        const marker = {
          icon: {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
          },
          scaledSize: new google.maps.Size([35, 40]),
          position: new this.google.maps.LatLng(lat, lng),
          reservationId: this.reservationId,
          stop: garage,
          isVisible: true,
          isWindowOpen: false,
        }

        this.garageMarkers.push(marker)
      })
    },
    async setHistoricalData(journey) {
      const { reservationId, journeyId, vehicle } = journey
      const vehicleId = vehicle.vehicleId
      let payload = { reservationId, idsList: [{ journeyId, vehicleId }] }
      const resp = await getTrackingHistoryV2(payload, 180, 120)
      if (!resp.data.trackingJourneyDataList) {
        return
      }
      this.trackingJourneyDataList[journeyId] =
        resp.data.trackingJourneyDataList

      this.hasBusifyData[vehicleId] = resp.data.trackingJourneyDataList.some(
        (tj) => tj.eldType === 'BUSIFY' && tj.gpsData.length > 0
      )
      this.hasDriverAppData[vehicleId] = resp.data.trackingJourneyDataList.some(
        (tj) => tj.eldType === 'COACHRAIL' && tj.gpsData.length > 0
      )
      this.hasELDData[vehicleId] = resp.data.trackingJourneyDataList.some(
        (tj) =>
          tj.eldType !== 'COACHRAIL' &&
          tj.eldType !== 'BUSIFY' &&
          tj.gpsData.length > 0
      )
      if (
        !this.hasELDData[vehicleId] &&
        !this.hasDriverAppData[vehicleId] &&
        this.hasBusifyData[vehicleId]
      ) {
        this.highlightedHistories.push(`${vehicleId}-busify`)
        journey.displayedSourceType = 'BUSIFY'
      } else if (
        !this.hasELDData[vehicleId] &&
        this.hasDriverAppData[vehicleId]
      ) {
        this.highlightedHistories.push(`${vehicleId}-driverApp`)
        journey.displayedSourceType = 'COACHRAIL'
      } else if (this.hasELDData[vehicleId]) {
        this.highlightedHistories.push(`${vehicleId}-eld`)
        journey.displayedSourceType = 'ELD'
      }
      let trackingJourneyDataList =
        this.trackingJourneyDataList[journeyId]?.filter(
          (tj) => tj.gpsData.length > 0
        ) || []
      for (const trackingJourney of trackingJourneyDataList) {
        if (trackingJourney.gpsData) {
          if (
            trackingJourney.eldType !== 'COACHRAIL' &&
            trackingJourney.eldType !== 'BUSIFY'
          ) {
            this.vehicleEldTypes[
              vehicleId
            ] = `${trackingJourney.eldType?.charAt(
              0
            )}${trackingJourney.eldType?.slice(1)?.toLowerCase()}`
          }
          const length = trackingJourney.gpsData.length
          if (
            !this.vehiclesWithTrackingHistory[vehicleId] ||
            this.vehiclesWithTrackingHistory[vehicleId] < length
          ) {
            this.vehiclesWithTrackingHistory[vehicleId] = length
          }
          if (length > 500) {
            const skipCount = Math.round(length / 500)
            const reducedGpsData = []
            for (let index = 0; index < length; index = index + skipCount) {
              reducedGpsData.push(trackingJourney.gpsData[index])
            }
            trackingJourney.gpsData = reducedGpsData
          }
          trackingJourney.gpsData = trackingJourney.gpsData.map((gps) => {
            return { ...gps, journeyId, eldType: trackingJourney.eldType }
          })
          this.historicalData.push(...trackingJourney.gpsData)
        }
      }
      if (
        this.trackingReservationStatus === ReservationStatus.Upcoming ||
        this.trackingReservationStatus === ReservationStatus.Started ||
        this.isPostTripLiveVehicleLocationVisible
      ) {
        this.vehicleTime = this.historicalMarkersRange[1]
      } else {
        this.vehicleTime = this.historicalMarkersRange[0]
      }
    },
    makeHistoricalMarkers(itemToPlot) {
      if (!itemToPlot) {
        return
      }

      const startTime = new Date(this.startDate).getTime() - 60 * 60 * 1000
      const tripStartDate = new Date(startTime)

      const endTime = new Date(this.endDate).getTime() + 60 * 60 * 1000
      const tripEndDate = new Date(endTime)

      const extendedTripTrackingIcon = {
        path: this.google.maps.SymbolPath.CIRCLE,
        fillColor: this.getColorForJourney(itemToPlot.journeyId),
        strokeColor: this.getColorForJourney(itemToPlot.journeyId),
        fillOpacity: 0.25,
        strokeWeight: 0,
        scale: 4,
      }
      const midTripTrackingIcon = {
        path: this.google.maps.SymbolPath.CIRCLE,
        fillColor: this.getColorForJourney(itemToPlot.journeyId),
        strokeColor: this.getColorForJourney(itemToPlot.journeyId),
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 4,
      }

      if (this.hasELDData[itemToPlot.vehicle.vehicleId]) {
        const gpsData = this.historicalData.filter((hd) => {
          return (
            hd.eldType !== 'COACHRAIL' &&
            hd.journeyId === itemToPlot.journeyId &&
            DateTime.fromISO(hd.reportedOn).toSeconds() >
              (this.sliderRange[0] || this.historicalMarkersRange[0]) &&
            DateTime.fromISO(hd.reportedOn).toSeconds() <
              (this.sliderRange[1] || this.historicalMarkersRange[1])
          )
        })
        gpsData.sort(this.sortJourney)
        gpsData.reverse()
        const eldType = gpsData[0]?.eldType

        const extendedTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) < tripStartDate ||
            new Date(gps.reportedOn) > tripEndDate
        )
        const midTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) >= tripStartDate &&
            new Date(gps.reportedOn) <= tripEndDate
        )
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          isVisible: false,
          eldType,
          path: extendedTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 0.5,
          strokeWidth: 3,
          strokeWeight: 3,
        })
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          isVisible: false,
          eldType,
          path: midTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 1,
          strokeWidth: 3,
          strokeWeight: 3,
        })
        for (const pos of extendedTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: extendedTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            reportedOn: pos.reportedOn,
            eldType,
          })
        }
        for (const pos of midTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: midTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            reportedOn: pos.reportedOn,
            eldType,
          })
        }
      }

      if (this.hasDriverAppData[itemToPlot.vehicle.vehicleId]) {
        const gpsData = this.historicalData.filter((hd) => {
          return (
            hd.eldType === 'COACHRAIL' &&
            hd.journeyId === itemToPlot.journeyId &&
            DateTime.fromISO(hd.reportedOn).toSeconds() >
              (this.sliderRange[0] || this.historicalMarkersRange[0]) &&
            DateTime.fromISO(hd.reportedOn).toSeconds() <
              (this.sliderRange[1] || this.historicalMarkersRange[1])
          )
        })
        gpsData.sort(this.sortJourney)
        gpsData.reverse()

        const extendedTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) < tripStartDate ||
            new Date(gps.reportedOn) > tripEndDate
        )
        const midTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) >= tripStartDate &&
            new Date(gps.reportedOn) <= tripEndDate
        )
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          eldType: 'COACHRAIL',
          isVisible: false,
          path: extendedTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 0.5,
          strokeWidth: 3,
          strokeWeight: 3,
        })
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          isVisible: false,
          eldType: 'COACHRAIL',
          path: midTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 1,
          strokeWidth: 3,
          strokeWeight: 3,
        })

        for (const pos of extendedTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: extendedTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            eldType: 'COACHRAIL',
            reportedOn: pos.reportedOn,
          })
        }
        for (const pos of midTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: midTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            eldType: 'COACHRAIL',
            reportedOn: pos.reportedOn,
          })
        }
      }
      if (this.hasBusifyData[itemToPlot.vehicle.vehicleId]) {
        const gpsData = this.historicalData.filter((hd) => {
          return (
            hd.eldType === 'BUSIFY' &&
            hd.journeyId === itemToPlot.journeyId &&
            DateTime.fromISO(hd.reportedOn).toSeconds() >
              (this.sliderRange[0] || this.historicalMarkersRange[0]) &&
            DateTime.fromISO(hd.reportedOn).toSeconds() <
              (this.sliderRange[1] || this.historicalMarkersRange[1])
          )
        })
        gpsData.sort(this.sortJourney)
        gpsData.reverse()

        const extendedTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) < tripStartDate ||
            new Date(gps.reportedOn) > tripEndDate
        )
        const midTripGpsData = gpsData.filter(
          (gps) =>
            new Date(gps.reportedOn) >= tripStartDate &&
            new Date(gps.reportedOn) <= tripEndDate
        )
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          eldType: 'BUSIFY',
          isVisible: false,
          path: extendedTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 0.5,
          strokeWidth: 3,
          strokeWeight: 3,
        })
        this.historicalMarkers.push({
          vehicleId: itemToPlot?.vehicle?.vehicleId,
          journeyId: itemToPlot.journeyId,
          isVisible: false,
          eldType: 'BUSIFY',
          path: midTripGpsData.map((pos) => ({
            lat: Number(pos.lat),
            lng: Number(pos.lng),
          })),
          strokeColor: this.getColorForJourney(itemToPlot.journeyId),
          strokeOpacity: 1,
          strokeWidth: 3,
          strokeWeight: 3,
        })

        for (const pos of extendedTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: extendedTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            eldType: 'BUSIFY',
            reportedOn: pos.reportedOn,
          })
        }
        for (const pos of midTripGpsData) {
          this.historicalDataPoints.push({
            position: { lat: pos.lat, lng: pos.lng },
            icon: midTripTrackingIcon,
            isVisible: false,
            vehicleId: itemToPlot.vehicle?.vehicleId,
            journeyId: itemToPlot.journeyId,
            eldType: 'BUSIFY',
            reportedOn: pos.reportedOn,
          })
        }
      }
    },
    sortJourney(a, b) {
      return a.reportedOn > b.reportedOn ? 0 : -1
    },
    async makeVehicleMarkers(journey) {
      const reservationId = this.reservationId
      let { journeyId, vehicle, driver } = journey
      const color = this.getColorForJourney(journeyId)
      let companyName = vehicle?.companyName
      if (
        this.isPreTripLiveVehicleLocationVisible ||
        this.isPostTripLiveVehicleLocationVisible ||
        this.trackingReservationStatus === ReservationStatus.Upcoming ||
        this.trackingReservationStatus === ReservationStatus.Started
      ) {
        let tracker = this.liveTrackers.find(
          (d) => d.vehicleId === vehicle.vehicleId
        )
        const lastKnown = tracker
        const previousKnown = tracker

        if (lastKnown && previousKnown) {
          const pointA = new this.google.maps.LatLng(
            previousKnown.lat,
            previousKnown.lng
          )
          const pointB = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )

          let heading = this.google.maps.geometry.spherical.computeHeading(
            pointA,
            pointB
          )
          this.bounds.extend(pointA)
          this.bounds.extend(pointB)

          const component = new BusIconClass({
            propsData: {
              color,
              heading,
              opacity:
                this.isPreTripLiveVehicleLocationVisible ||
                this.isPostTripLiveVehicleLocationVisible
                  ? 0.15
                  : 0.3,
            },
          })
          component.$mount()
          const icon = {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
            scaledSize: new this.google.maps.Size(60, 60),
            anchor: new this.google.maps.Point(30, 30),
          }

          const trackerReportTime = DateTime.fromISO(
            tracker.lastReportTime
          ).toJSDate()

          let blinkIcon
          if (numMinutesBetweenDateTimes(trackerReportTime, this.now) <= 5) {
            const blinkComponent = new BusIconClass({
              propsData: {
                color,
                heading,
                opacity:
                  this.isPreTripLiveVehicleLocationVisible ||
                  this.isPostTripLiveVehicleLocationVisible
                    ? 0.3
                    : 0.5,
              },
            })
            blinkComponent.$mount()
            blinkIcon = {
              url:
                'data:image/svg+xml,' +
                encodeURIComponent(blinkComponent.$el.outerHTML),
              scaledSize: new this.google.maps.Size(60, 60),
              anchor: new this.google.maps.Point(30, 30),
            }
          }

          if (!vehicle) {
            vehicle = {}
          }

          if (tracker?.lastReportTime) {
            vehicle.lastSyncTime = tracker.lastReportTime
          }

          const marker = {
            journeyId,
            isBusWindowOpen: false,
            isVisible: true,
            icon,
            blinkIcon,
            position: new this.google.maps.LatLng(lastKnown.lat, lastKnown.lng),
            reservationId,
            vehicle,
            driver,
            companyName,
            etas: [],
          }
          this.vehicleMarkers.push(marker)
        }
      } else {
        let x = this.historicalData.filter(
          (hd) =>
            hd.journeyId === journey.journeyId &&
            (hd.eldType === journey.displayedSourceType ||
              (journey.displayedSourceType === 'ELD' &&
                hd.eldType != 'COACHRAIL' &&
                hd.eldType != 'BUSIFY'))
        )

        x = x.sort((a, b) => {
          return (
            Math.abs(
              DateTime.fromISO(a.reportedOn).toSeconds() - this.vehicleTime
            ) -
            Math.abs(
              DateTime.fromISO(b.reportedOn).toSeconds() - this.vehicleTime
            )
          )
        })

        x = x[0]

        const lastKnown = x
        const previousKnown = x

        if (lastKnown && previousKnown) {
          const pointA = new this.google.maps.LatLng(
            previousKnown.lat,
            previousKnown.lng
          )
          const pointB = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )

          let heading = this.google.maps.geometry.spherical.computeHeading(
            pointA,
            pointB
          )
          this.bounds.extend(pointA)
          this.bounds.extend(pointB)

          const component = new BusIconClass({
            propsData: {
              color,
              heading,
            },
          })
          component.$mount()
          const icon = {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
            scaledSize: new this.google.maps.Size(60, 60),
            anchor: new this.google.maps.Point(30, 30),
          }

          let marker = this.vehicleMarkers.find(
            (marker) => marker.journeyId === journey.journeyId
          )

          if (!marker) {
            marker = {
              journeyId,
              isBusWindowOpen: false,
              isVisible: this.showLegend,
              icon,
              reservationId,
              vehicle,
              driver,
              companyName,
              etas: [],
            }
            this.vehicleMarkers.push(marker)
          }
          marker.vehicle.lastSyncTime = lastKnown.reportedOn
          marker.position = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )
        }
      }
      this.updateVehicleEtas(false)
    },
    makeStopMarkers() {
      if (!this.stops?.length) {
        return
      }
      let lastStopReached = this.isLiveVehicleLocationVisible
      // Aggregate stops by address lat/lng
      const aggregatedStops = this.stops.reduce((newList, stop) => {
        const {
          address: { lat, lng },
        } = stop

        let matchingStop = newList.find(
          (s) => s.address.lat === lat && s.address.lng === lng
        )

        if (!matchingStop) {
          // Create a list for each of the necessary information to be shown on stop info window
          matchingStop = {
            ...stop,
            repeatedStop: false,
            reachedList: [],
            orderIndexList: [],
            pickupDatetimeList: [],
            dropoffDatetimeList: [],
          }

          newList.push(matchingStop)
        } else {
          matchingStop.repeatedStop = true
        }

        const matchingJourneyStops = this.allJourneyStops.filter(
          (ajs) => ajs.stopId === stop.stopId
        )

        matchingStop.reachedList.push(
          !!matchingJourneyStops.length &&
            matchingJourneyStops.every((ajs) => ajs.reached)
        )

        matchingStop.orderIndexList.push(stop.orderIndex)
        if (stop?.pickupDatetime) {
          matchingStop.pickupDatetimeList.push(stop.pickupDatetime)
        }
        if (stop?.dropoffDatetime) {
          matchingStop.dropoffDatetimeList.push(stop.dropoffDatetime)
        }
        return newList
      }, [])
      aggregatedStops.forEach((stop) => {
        const {
          address: { lat, lng },
        } = stop
        this.bounds.extend({
          lat,
          lng,
        })

        let propsData = {
          number: Math.min(...stop.orderIndexList) + 1,
          repeatedStop: stop.repeatedStop,
        }
        // Every stop in aggregation must be reached to be completed
        const reached = !!stop.reachedList.every((reached) => reached)
        if (reached) {
          propsData.status = 'COMPLETED'
          stop.status = 'COMPLETED'
        } else if (lastStopReached) {
          propsData.status = 'EN ROUTE'
          stop.status = 'EN ROUTE'
        } else {
          propsData.status = 'TO DO'
          stop.status = 'TO DO'
        }
        let component = new StopIconClass({ propsData })
        component.$mount()

        const marker = {
          icon: {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
          },
          scaledSize: new google.maps.Size([35, 40]),
          position: new this.google.maps.LatLng(lat, lng),
          reservationId: this.reservationId,
          stop: stop,
          isVisible: true,
          isWindowOpen: false,
        }

        this.stopMarkers.push(marker)

        lastStopReached = reached
      })
    },
    highlightVehicles() {
      const highlightedVehicleKeys = [...this.highlightedVehicles.entries()]
        .filter(([k, v]) => v)
        .map(([k, v]) => k)
      for (const marker of this.vehicleMarkers) {
        marker.isVisible = highlightedVehicleKeys.includes(marker.journeyId)
      }
    },
    async changeSelectedProviders(selectedProviders) {
      this.selectedProviders = selectedProviders.filter(
        (provider) => provider.computedName !== 'All'
      )
      const tripVehicleGroupId = this.localCurrentTripVehicleGroups.map(
        (tvg) => tvg.tripVehicleGroupId
      )?.[0]
      this.changeSelectedTripVehicleGroup(tripVehicleGroupId)
    },
    async changeSelectedTripVehicleGroup(selectedTripVehicleGroupId) {
      this.selectedTripVehicleGroupId = selectedTripVehicleGroupId
      this.changeSelectedVehicleJourneys(
        this.currentJourneysBySelectedTripVehicleGroup
      )
    },
    async changeSelectedVehicleJourneys(selectedJourneys) {
      const oldSelectedJourneys = this.currentSelectedVehicleJourneys
      this.currentSelectedVehicleJourneys = selectedJourneys
      if (selectedJourneys.length === 0) {
        this.toggleDataTypes = false
      }

      const newlySelectedJourneys = this.currentSelectedVehicleJourneys.filter(
        (nj) =>
          !oldSelectedJourneys.some(
            (oj) => oj.vehicle.vehicleId === nj.vehicle.vehicleId
          )
      )
      const unselectedJourneys = oldSelectedJourneys.filter(
        (oj) =>
          !this.currentSelectedVehicleJourneys.some(
            (nj) => nj.vehicle.vehicleId === oj.vehicle.vehicleId
          )
      )

      for (const journey of newlySelectedJourneys) {
        if (this.hasELDData[journey.vehicle.vehicleId]) {
          this.highlightedHistories.push(`${journey.vehicle.vehicleId}-eld`)
        } else if (this.hasDriverAppData[journey.vehicle.vehicleId]) {
          this.highlightedHistories.push(
            `${journey.vehicle.vehicleId}-driverApp`
          )
        } else if (this.hasBusifyData[journey.vehicle.vehicleId]) {
          this.highlightedHistories.push(`${journey.vehicle.vehicleId}-busify`)
        }
        const marker = this.vehicleMarkers.find(
          (marker) => marker.journeyId === journey.journeyId
        )
        if (marker) {
          marker.isVisible = true
        }
        await this.highlightedVehicles.set(journey.journeyId, true)
      }

      for (const journey of unselectedJourneys) {
        const driverAppIndex = this.highlightedHistories.indexOf(
          `${journey.vehicle.vehicleId}-driverApp`
        )
        if (driverAppIndex >= 0) {
          this.highlightedHistories.splice(driverAppIndex, 1)
        }
        const eldIndex = this.highlightedHistories.indexOf(
          `${journey.vehicle.vehicleId}-eld`
        )
        if (eldIndex >= 0) {
          this.highlightedHistories.splice(eldIndex, 1)
        }
        await this.highlightedVehicles.set(journey.journeyId, false)
      }

      if (this.showAsDataPoints) {
        this.makeHistoricalDataPointMarkers()
      }
      if (
        this.isPreTripLiveVehicleLocationVisible ||
        this.isLiveVehicleLocationVisible ||
        this.isPostTripLiveVehicleLocationVisible ||
        this.showLegend
      ) {
        this.highlightVehicles()
      }
    },
    toggleEld(vehicleId) {
      const index = this.highlightedHistories.indexOf(`${vehicleId}-driverApp`)
      const busifyIndex = this.highlightedHistories.indexOf(
        `${vehicleId}-busify`
      )
      if (index >= 0) {
        this.highlightedHistories.splice(index, 1)
      }
      if (busifyIndex >= 0) {
        this.highlightedHistories.splice(busifyIndex, 1)
      }
      if (this.showAsDataPoints) {
        this.makeHistoricalDataPointMarkers()
      }
      for (let journey of this.adjustedJourneys) {
        if (journey.vehicle.vehicleId === vehicleId) {
          journey.displayedSourceType = 'ELD'
        }
      }
    },
    toggleDriverApp(vehicleId) {
      const eldIndex = this.highlightedHistories.indexOf(`${vehicleId}-eld`)
      const busifyIndex = this.highlightedHistories.indexOf(
        `${vehicleId}-busify`
      )
      if (eldIndex >= 0) {
        this.highlightedHistories.splice(eldIndex, 1)
      }
      if (busifyIndex >= 0) {
        this.highlightedHistories.splice(busifyIndex, 1)
      }
      if (this.showAsDataPoints) {
        this.makeHistoricalDataPointMarkers()
      }
      for (let journey of this.adjustedJourneys) {
        if (journey.vehicle.vehicleId === vehicleId) {
          journey.displayedSourceType = 'COACHRAIL'
        }
      }
    },
    toggleBusify(vehicleId) {
      const eldIndex = this.highlightedHistories.indexOf(`${vehicleId}-eld`)
      const driverAppIndex = this.highlightedHistories.indexOf(
        `${vehicleId}-driverApp`
      )
      if (eldIndex >= 0) {
        this.highlightedHistories.splice(eldIndex, 1)
      }
      if (driverAppIndex >= 0) {
        this.highlightedHistories.splice(driverAppIndex, 1)
      }
      if (this.showAsDataPoints) {
        this.makeHistoricalDataPointMarkers()
      }
      for (let journey of this.adjustedJourneys) {
        if (journey.vehicle.vehicleId === vehicleId) {
          journey.displayedSourceType = 'BUSIFY'
        }
      }
    },
    makeHistoricalDataPointMarkers() {
      this.clearDataPointMarkers()
      for (const dataPointMarker of this.visibleHistoricalDataPoints) {
        const datetime = DateTime.fromISO(dataPointMarker.reportedOn, {
          zone: this.firstTimezone,
        })
        const marker = new this.google.maps.Marker({
          icon: dataPointMarker.icon,
          position: new this.google.maps.LatLng(
            dataPointMarker.position.lat,
            dataPointMarker.position.lng
          ),
        })
        const infoWindow = new this.google.maps.InfoWindow({
          content: datetime.toFormat('t ZZZZ'),
        })
        marker.addListener('mouseover', () => {
          infoWindow.open({
            anchor: marker,
            map: this.map,
          })
        })
        marker.addListener('mouseout', () => {
          infoWindow.close()
        })
        this.historicalDataPointMarkers.push(marker)
      }
      if (this.showAsDataPoints) {
        this.showDataPointMarkers()
      }
    },
    showDataPointMarkers() {
      for (const marker of this.historicalDataPointMarkers) {
        marker.setMap(this.map)
      }
    },
    hideDataPointMarkers() {
      for (const marker of this.historicalDataPointMarkers) {
        marker.setMap(null)
      }
    },
    clearDataPointMarkers() {
      this.hideDataPointMarkers()
      this.historicalDataPointMarkers = []
    },
    updateVehicleTime(newVehicleTime) {
      this.vehicleTime = newVehicleTime
      const reservationId = this.reservationId
      for (const journey of this.adjustedJourneys) {
        let { journeyId, vehicle, driver } = journey
        let companyName = vehicle?.companyName
        let x = this.historicalData.filter(
          (hd) =>
            hd.journeyId === journey.journeyId &&
            (hd.eldType === journey.displayedSourceType ||
              (journey.displayedSourceType === 'ELD' &&
                hd.eldType != 'COACHRAIL' &&
                hd.eldType != 'BUSIFY'))
        )

        x = x.sort((a, b) => {
          return (
            Math.abs(
              DateTime.fromISO(a.reportedOn).toSeconds() - newVehicleTime
            ) -
            Math.abs(
              DateTime.fromISO(b.reportedOn).toSeconds() - newVehicleTime
            )
          )
        })

        x = x[0]

        const lastKnown = x
        const previousKnown = x

        if (lastKnown && previousKnown) {
          const pointA = new this.google.maps.LatLng(
            previousKnown.lat,
            previousKnown.lng
          )
          const pointB = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )

          let heading = this.google.maps.geometry.spherical.computeHeading(
            pointA,
            pointB
          )
          this.bounds.extend(pointA)
          this.bounds.extend(pointB)

          let propsData = {
            color: this.getColorForJourney(journeyId),
            heading,
          }
          let component = new BusIconClass({ propsData })
          component.$mount()

          const icon = {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
            scaledSize: new this.google.maps.Size(60, 60),
            anchor: new this.google.maps.Point(30, 30),
          }

          if (!vehicle) {
            vehicle = {}
          }
          let marker = this.vehicleMarkers.find(
            (marker) => marker.journeyId === journey.journeyId
          )

          if (!marker) {
            marker = {
              journeyId,
              isBusWindowOpen: false,
              isVisible: true,
              icon,
              reservationId,
              vehicle,
              driver,
              companyName,
              etas: [],
            }
            this.vehicleMarkers.push(marker)
          }
          let state = marker.isVisible
          marker.isVisible = false
          marker.vehicle.lastSyncTime = lastKnown.reportedOn
          marker.position = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )
          marker.isVisible = state
        }
      }
    },
    updateSliderTime(newStartTime, newEndTime) {
      this.sliderRange[0] = newStartTime
      this.sliderRange[1] = newEndTime
      if (this.isTrackingVisible) {
        this.historicalMarkers = []
        this.historicalDataPoints = []
        for (const journey of this.adjustedJourneys) {
          this.makeHistoricalMarkers(journey)
        }
        this.makeHistoricalDataPointMarkers()
      }
    },
    updateVehicleEtas(openBusWindow = true) {
      this.vehicleMarkers.forEach((vehicleMarker) => {
        vehicleMarker.etas = []
        this.stops.forEach((stop) => {
          let estimate = this.distanceAndDuration(
            vehicleMarker.vehicle.vehicleId,
            stop.address.addressId
          )
          if (estimate) {
            const { arrivalTime, duration, timeZone } = estimate.stop
            vehicleMarker.etas.push({
              stopNumber: stop.orderIndex + 1,
              arrivalTime: DateTime.fromISO(arrivalTime, { zone: timeZone }),
              duration,
              timeZone,
            })
          }
        })
        vehicleMarker.isBusWindowOpen = openBusWindow
      })
    },
    mapVehiclesToAddressAndJourneyStop: function (stop) {
      const vehicleAddresses = new Map()
      this.adjustedJourneys.forEach((journey) => {
        const { vehicle } = journey
        journey.journeyStops.find((journeyStop) => {
          if (journeyStop.stop.stopId === stop.stopId) {
            vehicleAddresses.set(vehicle.vehicleId, {
              address: stop.address,
              journeyStopId: journeyStop.journeyStopId,
            })
          }
        })
      })
      return vehicleAddresses
    },
    getColorForJourney(id) {
      let journeyIndex = this.adjustedJourneys
        .map((journey) => journey.journeyId)
        .indexOf(id)
      if (journeyIndex < 0) {
        journeyIndex = 0
      }
      return this.colors[journeyIndex % 8].hex
    },
    getColorForStop(stop) {
      if (stop.reached) {
        return this.$cr.theme.green
      } else if (
        !stop.reached &&
        (stop.orderIndex === 0 || this.stops[stop.orderIndex - 1].reached) &&
        (this.trackingAllocation === ReservationTrackingAllocation.Partial ||
          this.trackingAllocation === ReservationTrackingAllocation.All) &&
        this.isReservationStarted
      ) {
        return this.$cr.theme.blue
      } else {
        return this.$cr.theme.grayDark
      }
    },
    getJourneyVehicleName(journeyVehicle) {
      const simulatedVehicle = this.simulatedTripAssignmentVehicles[
        journeyVehicle.vehicleId
      ]
      if (simulatedVehicle) {
        return simulatedVehicle.vehicleName
      }

      return journeyVehicle.vehicleName
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-table thead tr:first-child {
  border-bottom: none;
  height: 0px;
}

.itinerary-table {
  min-height: 85vh;
}

.itinerary-table td {
  font-size: 13px;
  color: #3d4352 !important;
  padding-right: 0px;
  padding-left: 0px;
  height: min-content;
}

.itinerary-table tr {
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}

::v-deep .v-list__tile.theme--light {
  height: min-content;
  padding-left: 8px;
  padding-right: 8px;
}

.address-title {
  word-wrap: break-word;
  color: $gray-medium-dark;
}

.time-column {
  min-width: 195px;
  max-width: 195px;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

::v-deep .v-chip .v-chip__content {
  cursor: default;
  text-align: center;
  height: min-content;
}

.status-chip {
  font-weight: bold;
  font-size: 11px;
}

.map-container {
  padding: 10px 15px;
  font-size: 14px;
  background-color: white;
  border: 1em;
  border-radius: 10px;
  box-shadow: 0px 3px 4px rgba($black-base, 0.18);

  .v-input {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  &--journeys {
    width: 400px;
    margin-left: 243px;
  }

  &--timezones {
    width: 310px;
    margin-left: 374px;
  }

  &--legend {
    margin-left: 6px;
    width: max-content;
    height: min-content;
    position: fixed;
  }
}

::v-deep .gm-ui-hover-effect {
  display: none !important;
}

::v-deep .gm-style-iw-ch {
  display: none !important;
}

::v-deep .gm-style .gm-style-iw-d {
  overflow: auto !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 10px !important;
}

.tracking-summary-title {
  background-color: $blue-light;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin: -10px 0 5px -10px;
  position: absolute;
  top: -5px;
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
  color: $gray;
}

::v-deep .v-expansion-panel {
  box-shadow: none;
  border: 1px solid $gray-light;
  border-radius: 5px;

  &__container {
    border-radius: 5px;
  }

  &__header {
    padding-top: 16px;
    padding-bottom: 6px;

    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 16px;
      margin-right: 16px;
    }
  }
}

.v-list__tile__action {
  margin-top: 0px;
}

.itinerary-sidebar {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: min-content;
  flex-wrap: wrap;
}

.journey-checkbox {
  &--blue {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $primary !important;
      caret-color: $primary !important;
    }
  }

  &--purple {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $purple !important;
      caret-color: $purple !important;
    }
  }

  &--green {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $green !important;
      caret-color: $green !important;
    }
  }

  &--orange {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $orange !important;
      caret-color: $orange !important;
    }
  }
}

.legend-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;

  p {
    margin: 0 0 0 5px;
  }

  .legend-list-box {
    width: 13px !important;
    height: 13px !important;
    margin: 0 8px;
    flex-shrink: 0;
    border-radius: 50%;
  }
}

.legend-cont {
  position: absolute;
  left: 50px;
  z-index: 9999;
}

.top-left {
  &__container {
    position: absolute;
    padding-left: 10px;
    padding-top: 10px;
    z-index: 1;
  }
}

.top-right {
  &__container {
    padding-top: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    z-index: 1;
    display: inline-flex;
    .v-btn--icon {
      &::before {
        border-radius: unset !important;
      }
    }
  }
}

.comments-list-container {
  max-height: 200px;
  margin-top: 0;
  width: 100%;
  overflow: auto;

  .comments-list {
    padding: 4px;
  }

  .comment-container {
    padding-top: 7px;
    padding-bottom: 7px;

    &:not(:first-of-type) {
      padding-top: 0px;
    }

    .comment-author {
      color: $gray-medium-dark;
      font-size: 12px;
    }

    .comment-body {
      font-size: 13px;
    }
  }
}

.data-points-checkbox ::v-deep {
  align-items: center;
  max-height: 37px;
  border-radius: 5px;
  margin-top: 5px !important;
  padding: 0px 10px;

  .theme--light.v-label {
    color: $gray-dark;
  }
}

.bottom-middle {
  &__container {
    z-index: 1;
    position: absolute;
    left: 2%;
    bottom: 3%;
    margin: 0 auto;
    width: 90%;
    display: grid;
    justify-content: right;
  }
}

.refresh-btn {
  margin-top: 10px;
  margin-left: 10px;
  color: $white;
  background-color: $primary;
}

.map-cont {
  width: 100%;
  height: inherit;
}

.drop-shadow {
  box-shadow: 0px 3px 4px rgba($black-base, 0.18) !important;
}

@media (max-width: 599px), (max-height: 599px) {
  .legend-btn__on .legend-btn__off {
    display: none;
  }
}

.legend-item {
  margin-top: 6px;
}

::v-deep .v-btn:hover:before {
  background-color: white;
}

.legend-btn {
  &__on {
    color: $gray-dark;
    background-color: #cde1ee !important;
    font-size: 16px;

    &:hover {
      background-color: #edf4fa !important;
    }
  }
  &__off {
    color: $gray-dark;
    background-color: $white;
    font-size: 16px;

    &:hover {
      background-color: #edf4fa !important;
    }
  }
}

.data-button-toggle {
  border: 1px solid $gray-light !important;
  padding: 5px 10px;
  font-size: 12px;
}

.expand-timeline-button {
  &__collapse {
    position: relative;
    min-width: 45px;
    max-width: 45px;
    height: 28px;
    border-radius: 5px 5px 0px 0px !important;
    left: 92%;
  }
  &__expand {
    width: min-content;
    height: 35px;
    border-radius: 5px !important;
    position: fixed;
    bottom: 3%;
    right: 60px !important;
    box-shadow: 0px 3px 4px rgba($black-base, 0.18) !important;

    &:hover {
      background-color: #edf4fa !important;
    }
  }
}

::v-deep .v-input--switch__track.primary--text {
  color: $blue-dull !important;
}
::v-deep .v-input--switch__thumb {
  height: 9px !important;
  width: 9px !important;
  top: calc(50% - 5px) !important;
  right: -5px;
}
::v-deep .v-input--switch__thumb.primary--text {
  color: $primary !important;
  right: -8px !important;
}
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;
}
::v-deep {
  .table-toggle {
    .v-btn:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    .v-btn:last-child {
      border-radius: 0 5px 5px 0 !important;
    }

    .v-btn.v-btn--active {
      background-color: $blue-dark;
    }
  }
}

.tracking-summary-tooltip {
  max-width: 200px;
}
</style>
